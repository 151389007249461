<template>
  <div class="actions">
    <router-link
      :to="{name: 'detail_examen_clinique', params: {uid: object.uid}}"
      class="btn btn-secondary btn-sm"
    >
      <i class="icofont icofont-eye" />
    </router-link>
    <a
      class="btn btn-third btn-sm"
      @click.prevent="deleting"
      v-if="canDelete"
    > <i class="icofont icofont-close" /></a>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { DELETE_EXAMEN_CLINIQUE } from '../../../../graphql/sanitaire';
export default {
    props: {
        object: {type: Object, required: true}
    },
    data(){
        return {

        }
    },
    methods: {
        ...mapMutations({
            setReview: 'sanitaire/SET_REVEIW_EXAMEN'
        }),
        settingReview(){
            this.setReview(JSON.parse(this.object.detail))
        },
        deleting(){
            this.$apollo.mutate({
                mutation: DELETE_EXAMEN_CLINIQUE,
                variables: {
                    "uid": this.object.uid
                },
                update: (data) => {
                    console.log(`Examen clinique ${data.uid} delete successfully`)
                }
              })
        }
    },
    computed: {
      ...mapGetters({
        is_veterinaire: 'auth/is_veterinaire',
        is_super_admin: 'auth/is_super_admin',
        is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
        is_assistant_veto_major: 'auth/is_assistant_veto_major',
      }),
      canDelete(){
        return this.is_super_admin || this.is_veterinaire
      }
    }

}
</script>

<style lang="scss">
.actions{
    a{
        margin: 5px;
    }
}

</style>